<style>
.responsive-datepicker .dropdown-menu {
    left: auto !important;
    right: 0 !important;
    transform-origin: top right;
}
@media (min-width: 1280px) {
    .responsive-datepicker .dropdown-menu {
        left: auto !important;
        right: 9% !important;
        transform-origin: top right;
    }
}
</style>
<template>
    <b-overlay :show="isLoading">
        <b-card style="background-color: #fff;" id="container-production-line">
            <ValidationObserver ref="observer" v-slot="{ passes }">
                <b-form @submit.prevent="passes(saveProductionLine)" id="production-line-form">
                    <b-row class="mb-2">
                        <b-col md="2" sm="12">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Variety" :invalid-feedback="errors[0]" :state="errors[0] ? false : null">
                                    <v-select label="text" :options="varieties" @search="getVarieties" @search:focus="getVarieties"
                                              v-model="productionLine.variety" :filterable="false" :disabled="productionLine.productionLineOccupancy !== null"
                                              placeholder="Select variety" :state="errors[0] ? false : null">
                                        <template #no-options>No results</template>
                                    </v-select>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="2" sm="12">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Crop" :invalid-feedback="errors[0]">
                                    <b-form-input v-model="productionLine.crop" autocomplete="false" disabled
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="2" sm="12">
                            <ValidationProvider rules="required|integer|min_value:1" v-slot="{ errors }">
                                <b-form-group label="Year" :invalid-feedback="errors[0]">
                                    <b-form-input v-model="productionLine.orderYear" autocomplete="false"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="2" sm="12">
                            <ValidationProvider rules="required|integer|min_value:1" v-slot="{ errors }">
                                <b-form-group label="Season" :invalid-feedback="errors[0]">
                                    <b-form-input v-model="productionLine.season" autocomplete="false"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="2" sm="12">
                            <ValidationProvider rules="required|integer|min_value:1" v-slot="{ errors }">
                                <b-form-group label="Review" :invalid-feedback="errors[0]">
                                    <b-form-input v-model="productionLine.sequence" autocomplete="false"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="2" sm="12">
                            <b-form-group label="Order">
                                <b-form-input v-model="productionLine.orderId" autocomplete="false"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="2" sm="12">
                            <b-form-group label="Type">
                                <v-select label="title" :options="types" @search:focus="getVarieties" @search="getVarieties"
                                          :reduce="type => type.id" v-model="productionLine.type"
                                          placeholder="Select type" >
                                    <template #no-options>No results</template>
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="2" sm="12">
                            <b-form-group label="BIO/GSPP">
                                <b-form-input v-model="productionLine.bioGspp" autocomplete="false"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="2" sm="12">
                            <b-form-group label="MS %">
                                <b-form-input v-model="productionLine.productionLineMs" autocomplete="false"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="2" sm="12">
                            <b-form-group label="MS">
                                <v-select label="text" v-model="productionLine.msVariety" :options="['1 Non sterile', '2 Sterile']">
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="2" sm="12">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Female parent" :invalid-feedback="errors[0]">
                                    <b-form-input v-model="productionLine.parentalFemaleName" autocomplete="false" disabled
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="2" sm="12">
                            <b-form-group label="PLD (female)">
                                <b-form-input v-model="productionLine.femalePld" autocomplete="false"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-form-group label="Remarks stockseed female">
                                <b-form-textarea v-model="productionLine.remarkStockseedFemale" rows="3"></b-form-textarea>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="2" sm="12">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Male parent" :invalid-feedback="errors[0]">
                                    <b-form-input v-model="productionLine.parentalMaleName" autocomplete="false" disabled
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="2" sm="12">
                            <b-form-group label="PLD (male)">
                                <b-form-input v-model="productionLine.malePld" autocomplete="false"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-form-group label="Remarks stockseed male">
                                <b-form-textarea v-model="productionLine.remarkStockseedMale" rows="3"></b-form-textarea>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="2" sm="12">
							<ValidationProvider v-slot="{ errors }">
								<b-form-group label="Change date" :invalid-feedback="errors[0]">
									<custom-datepicker :container="'#container-production-line'" v-model="productionLine.changeDate" :state="errors[0] ? false : null" />
								</b-form-group>
							</ValidationProvider>
                        </b-col>
                        <b-col md="2" sm="12">
                            <ValidationProvider v-slot="{ errors }" rules="required|number|min_value:0.01">
                                <b-form-group label="Order Kg" :invalid-feedback="errors[0]">
                                    <b-form-input v-model="productionLine.orderKg" autocomplete="false" :disabled="productionLine.id > 0 && (productionLine.parentId > 0 || productionLine.children.length > 0)"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="2" sm="12">
                            <ValidationProvider v-slot="{ errors }" rules="required|number|min_value:0">
                                <b-form-group label="Yield NL" :invalid-feedback="errors[0]">
                                    <b-form-input v-model="productionLine.yieldNl" autocomplete="false"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="2" sm="12">
                            <ValidationProvider>
                                <b-form-group label="kg/ha, g/plant">
                                    <b-form-input v-model="productionLine.yieldUnits" autocomplete="false"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="2" sm="12">
                            <ValidationProvider v-slot="{ errors }" rules="integer|min_value:1">
                                <b-form-group label="Female nr. of plants net" :invalid-feedback="errors[0]">
                                    <b-form-input v-model="productionLine.femalePlantsNl" autocomplete="false"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="2" sm="12">
                            <b-form-group label="Prod. area">
                                <b-form-input v-model="productionLine.productionArea" autocomplete="false"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="2" sm="12">
                            <b-form-group label="Growth tech">
                                <b-form-input v-model="productionLine.growthTech" autocomplete="false"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="2" sm="12">
                            <b-form-group label="Location">
                                <b-form-input v-model="productionLine.location" autocomplete="false"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-form-group label="Remarks">
                                <b-form-textarea v-model="productionLine.remarks" rows="3"></b-form-textarea>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="2" sm="12">
                            <b-form-group label="Priority">
                                <b-form-input v-model="productionLine.priority" autocomplete="false"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="2" sm="12">
							<ValidationProvider v-slot="{ errors }">
								<b-form-group label="Delivery seeds date" :invalid-feedback="errors[0]">
									<custom-datepicker :container="'#container-production-line'" v-model="productionLine.deliverDate" :state="errors[0] ? false : null" />
								</b-form-group>
							</ValidationProvider>
                        </b-col>
                        <b-col md="2" sm="12">
							<ValidationProvider v-slot="{ errors }">
								<b-form-group label="Ultimate seeds date" :invalid-feedback="errors[0]">
									<custom-datepicker :container="'#container-production-line'" v-model="productionLine.ultimateShipmentDate" :state="errors[0] ? false : null" />
								</b-form-group>
							</ValidationProvider>
                        </b-col>
                        <b-col md="2" sm="12">
                            <ValidationProvider v-slot="{ errors }" rules="number|min_value:0.01">
                                <b-form-group label="Yield calculated" :invalid-feedback="errors[0]">
                                    <b-form-input v-model="productionLine.yieldCalculated" autocomplete="false"
                                        :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="2" sm="12">
                            <ValidationProvider v-slot="{ errors }" rules="number|min_value:0.01">
                                <b-form-group label="Ratio" :invalid-feedback="errors[0]">
                                    <b-form-input v-model="productionLine.malePerFemaleRatio" autocomplete="false"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="2" sm="12">
                            <ValidationProvider v-slot="{ errors }" rules="number|min_value:0.01">
                                <b-form-group label="Density" :invalid-feedback="errors[0]">
                                    <b-form-input v-model="productionLine.density" autocomplete="false"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="2" sm="12">
                            <ValidationProvider v-slot="{ errors }" rules="integer|min_value:0">
                                <b-form-group label="Females needed" :invalid-feedback="errors[0]">
                                    <b-form-input v-model="productionLine.femalePlantsMx" autocomplete="false"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="2" sm="12">
                            <ValidationProvider v-slot="{ errors }" rules="integer|min_value:0">
                                <b-form-group label="Males needed" :invalid-feedback="errors[0]">
                                    <b-form-input v-model="productionLine.malesNeeded" autocomplete="false"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="2" sm="12">
                            <ValidationProvider v-slot="{ errors }">
                                <b-form-group label="Total plants">
                                    <b-form-input v-model="productionLine.totalPlants" autocomplete="false" disabled
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="2" sm="12">
                            <ValidationProvider v-slot="{ errors }" rules="integer|min_value:0">
                                <b-form-group label="Plants per line" :invalid-feedback="errors[0]">
                                    <b-form-input v-model="productionLine.plantsPerLine" autocomplete="false"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="2" sm="12">
                            <ValidationProvider v-slot="{ errors }" rules="required|number|min_value:0.01">
                                <b-form-group label="Rows required" :invalid-feedback="errors[0]">
                                    <b-form-input v-model="productionLine.rowsRequired" autocomplete="false" :disabled="productionLine.productionLineOccupancy !== null"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="2" sm="12">
                            <ValidationProvider v-slot="{ errors }" rules="required|number|min_value:0.01">
                                <b-form-group label="Surface req. (m²)" :invalid-feedback="errors[0]">
                                    <b-form-input v-model="productionLine.surfaceRequired" autocomplete="false" :disabled="productionLine.productionLineOccupancy !== null"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="2" sm="12">
							<ValidationProvider v-slot="{ errors }" rules="required">
								<b-form-group label="Order date" :invalid-feedback="errors[0]">
									<custom-datepicker :container="'#container-production-line'" v-model="productionLine.orderDate" :state="errors[0] ? false : null" />
								</b-form-group>
							</ValidationProvider>
                        </b-col>
                    </b-row>
                </b-form>
            </ValidationObserver>
            <template #footer>
                <div class="text-right">
                    <b-button variant="outline-secondary" :to="{ name: 'ProductionLineIndex' }" class="mr-2"><font-awesome-icon icon="angle-left"></font-awesome-icon> Back</b-button>
                    <b-button variant="secondary" :to="{ name: 'ProductionLineShow', params: { id: productionLine.id, variety: productionLine.variety.text }}" v-if="productionLine.id" class="mr-2">
                        <font-awesome-icon icon="search"></font-awesome-icon> Detail
                    </b-button>
                    <b-button type="submit" form="production-line-form" variant="primary"><font-awesome-icon icon="save"></font-awesome-icon> Save</b-button>
                </div>
            </template>
        </b-card>
    </b-overlay>
</template>

<script>
import { extend } from 'vee-validate';
import { integer, min_value, required } from 'vee-validate/dist/rules';
import { getProductionLineByIdUrl, getVarietyByIdUrl, productionLineSaveUrl, varietiesSelectUrl } from '@routes';
import { ProductionLineEntity } from '@/models/productionLineEntity';
import Utilities, { formatDate } from '@/js/utilities';
import customDatepicker from '@components/customDatepicker.vue';
import 'bootstrap-datepicker';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css';

extend('required', {
    ...required,
    message: 'Field required'
});

extend('number', value => {
    if (Utilities.isNumeric(value)) {
        return true;
    }
    return 'Must be number';
});

extend('integer', {
    ...integer,
    message: 'Must be integer'
});

extend('min_value', {
    ...min_value,
    message: 'Must be greater than 0'
});

export default {
    components: { customDatepicker },
    data() {
        return {
            productionLine: ProductionLineEntity.create(),
            swalTitle: 'Production lines',
            breadcrumb: {
                title: 'Production lines',
                path: [
                    {
                        name: 'ProductionLineIndex',
                        text: 'Production lines'
                    }
                ]
            },
            action: 'Add',
            signature: null,
            oldSignature: null,
            isLoading: false,
            file: null,
            varieties: [],
            toastTitle: 'Production lines',
            types:[
                {
                    id: 'BREEDING',
                    title: 'Breeding'
                },
                {
                    id: 'BUFFER',
                    title: 'Buffer'
                },
                {
                    id: 'COMMERCIAL',
                    title: 'Commercial'
                },
                {
                    id: 'FREE_PROGRAM',
                    title: 'Free program'
                },
                {
                    id: 'TRIAL',
                    title: 'Trial'
                }
            ]
        };
    },
    created() {
        if (!this.$route.name.includes('Edit')) {
            this.breadcrumb.path.push({
                name: this.$route.name,
                text: `${this.action}`
            });

            this.$emit('update-breadcrumb', this.breadcrumb);
        } else {
            this.loadData();
        }
    },
    methods: {
        showInfoBreadCrumb() {
            if (this.$route.name.includes('Edit')) {
                let text = '';

                text += this.productionLine.varietyName + (this.productionLine.tag ? '-' + this.productionLine.tag : '');

                this.action = `${ text } / Edit`;

                this.breadcrumb.path.push({
                    name: this.$route.name,
                    text: `${this.action}`
                });

                this.$emit('update-breadcrumb', this.breadcrumb);
            }
        },
        loadData() {
            this.isLoading = true;

            this.axios.get(getProductionLineByIdUrl(this.$route.params.id)).then(response => {
                this.productionLine = JSON.parse(JSON.stringify(response.data.productionLine));
                this.$nextTick(() => {
                    this.formatDatepicker(this.productionLine.orderDate, 'orderDate');
                    this.formatDatepicker(this.productionLine.ultimateShipmentDate, 'ultimateSeedsDate');
                    this.formatDatepicker(this.productionLine.deliverDate, 'deliverySeedsDate');
                    this.formatDatepicker(this.productionLine.changeDate, 'changeDate');
                });
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() => {
                this.isLoading = false;

                this.$nextTick(() => {
                    this.showInfoBreadCrumb();
                });
            });
        },
        saveProductionLine() {
            this.isLoading = true;

            this.axios.post(productionLineSaveUrl(), this.productionLine).then(response => {
                if (response.status === 200) {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'success'
                    });
                    this.$router.push({
                        name: 'ProductionLineShow',
                        params:
                        {
                            id: response.data.id,
                            successMessage: response.data.message,
                            variety: response.data.varietyName
                        }
                    });
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        getVarieties(text, loading) {
            if (loading) {
                loading(true);
            }

            this.varieties = [];
            this.axios.post(varietiesSelectUrl(), {
                search: text
            }).then(response => {
                this.varieties = response.data.variety;
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() => {
                if (loading) {
                    loading(false);
                }
            });
        },
        formatDatepicker(value, ref) {
            if (value) {
                this.$nextTick(() => {
                    this.$refs[ref].formattedValue = formatDate(value);
                });
            }
        }
    },
    watch: {
        'productionLine.variety': {
            handler() {
                this.axios.get(getVarietyByIdUrl(this.productionLine.variety.value)).then(response => {
                    let variety = response.data.variety;
                    this.productionLine.parentalFemaleName = variety.parentalFemale;
                    this.productionLine.parentalMaleName = variety.parentalMale;
                    this.productionLine.cropFamily = variety.crop;
                    this.productionLine.crop = variety.crop;
                    this.productionLine.parentalMaleId = variety.parentalMaleId;
                    this.productionLine.parentalFemaleId = variety.parentalFemaleId;
                }).catch(error => {
                    if (error.response) {
                        this.$bvToast.toast(error.response.data.message, {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    } else if (error.request) {
                        this.$bvToast.toast('An error occurred while executing the request', {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    } else {
                        this.$bvToast.toast('An error occurred while configuring the request', {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    }
                });
            },
            deep: true
        },
        'productionLine.femalePlantsMx': {
            handler() {
                this.productionLine.totalPlants = Number(this.productionLine.femalePlantsMx) + Number(this.productionLine.malesNeeded);
            },
            deep:true
        },
        'productionLine.malesNeeded': {
            handler() {
                this.productionLine.totalPlants = Number(this.productionLine.femalePlantsMx) + Number(this.productionLine.malesNeeded);

            },
            deep:true
        }
    }
};
</script>